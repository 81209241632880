import React from "react";
import { graphql } from "gatsby";
import PageWithCmsWrapper from "../../components/shared/page-with-cms-wrapper";

const Features = ({
                     path,
                     data: {
                       strapiFeatures: {
                         Title,
                         Content,
                         SeoTitle,
                         MetaDescriptions,
                       },
                     },
                   }) => {

  return (
    <PageWithCmsWrapper
      path={path}
      title={Title}
      content={Content}
      seoProps={{ title: SeoTitle, description: MetaDescriptions}}
    />
  );
};

export default Features;

export const query = graphql`
  query FeaturesQuery {
    strapiFeatures {
      Title
      Content
      SeoTitle
      MetaDescriptions
    }
  }
`;
